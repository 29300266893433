




































import { ref, onMounted } from '@vue/composition-api';
import { useAuthActions, useToolActions, useAuthGetters, useDbGetters } from '@/store';

export default {
  name: 'ConfirmEmail',

  props: {
    token: {
      type: String,
      default: ''
    },
    tokenId: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    }
  },

  setup(props, vm) {
    const color = ref('blue');
    const email = ref('');
    const confirmationError = ref(false);
    const { collection } = useDbGetters(['collection']);
    const displayMessage = ref('Hang on while we verify your email');
    const messagePopup = ref(false);
    const { setLinearLoader } = useToolActions(['setLinearLoader']);
    const { sendResetPassword } = useAuthActions(['sendResetPassword']);

    const resendConfirmation = async () => {
      const { resendEmailConfirmation } = useAuthActions(['resendEmailConfirmation']);
      await resendEmailConfirmation({ email: email.value });
    };

    const verifyUser = async () => {
      const { confirmUser } = useAuthActions(['confirmUser']);
      try {
        await confirmUser({
          token: props.token as string,
          tokenId: props.tokenId as string,
          username: decodeURIComponent(props.username as string)
        });
        await collection.value!('User')
          .findOne({ email: decodeURIComponent(props.username as string) })
          .then(async res => {
            color.value = 'green';
            displayMessage.value = 'Your email has been verified, you will be redirected shortly';
            if (res && res.invitedUser) {
              await sendResetPassword({ email: decodeURIComponent(props.username as string) }).then(
                () => {
                  messagePopup.value = true;
                }
              );
            } else if (localStorage.getItem('inviteSlug')) {
              vm.root.$router.push({
                path: `/invite/${localStorage.getItem('inviteSlug')}?isLogin=true`
              });
            } else {
              vm.root.$router.push({ name: 'login' });
            }
          });
      } catch {
        confirmationError.value = true;
        color.value = 'red';
        displayMessage.value = 'We could not verify your email at this time';
      }
    };

    onMounted(async () => {
      await setLinearLoader({ func: verifyUser });
      if (messagePopup.value) {
        color.value = 'green';
        displayMessage.value =
          'We have sent you instructions in your registered email address about setting up a password for the PilotCity account';
      }
    });

    return {
      confirmationError,
      displayMessage,
      color,
      email,
      messagePopup,
      resendConfirmation
    };
  }
};
